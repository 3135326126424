import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants'
import DealsFilter from '../SpecialDeals/DealsFilter';
import Divider from '@mui/material/Divider';
import { DealerContext } from "../shared/contexts/DealerContext";
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingTop: '130px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: '40px',
    '@media (max-width: 959px)': {
      paddingTop: '80px',
    }
  },
  innerHolder: {
    maxWidth: '1360px',
  },
  CardHolder: {
    background: "white",
    boxShadow: "0 0 5px 0 #001e4c70",
    padding: "30px 0px",
    marginTop: "20px",
    '@media (max-width: 959px)': {
      margin: '10px',
    }
  },
  CardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #001e4c70",
    padding: "0px 0px",
    marginTop: "20px",
    width: 'calc(100%/2 - 10px)',
    '@media (max-width: 959px)': {
      width: 'calc(100%)',
      margin: '10px',
    }
  },
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
    '@media (max-width: 959px)': {
      padding: '0px 10px',
    }
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 0px",
    flexDirection: "column",
  },
  title: {
    padding: "5px 30px",
    display: "flex",
    justifyContent: "space-between",
    '@media (max-width: 959px)': {
      padding: '0px 10px',
    }
  },
  titleContactUs: {
    padding: "5px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardContentItem: {
    display: 'flex',
    padding: '10px 0px',
    borderBottom: '2px #8080802e solid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  CardHolderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  accent: {
    color: '#2c88d9 '
  },
  tc: {
    paddingTop: '20px',
    maxWidth: '1360px',
    textAlign: 'justify'
  },
  stickyDiv: {
    position: 'sticky',
    top: '80px', // Adjust this value as needed
    alignSelf: 'flex-start', // This ensures the div doesn't stretch to fill the container
    padding: '10px',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto',
    '@media (max-width: 959px)': {
      display: 'none',
    }
  },
  newSecondaryColorC: {
    color: "#53aca2",
    textAlign: 'center',
    fontSize: '16px'
  },
  vehicleTitle: {
    color: "#003145",
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    color: "#003145",
    '@media (max-width: 959px)': {
      fontSize: '2.125rem',
    }
  },
}));

const DetailedSpecialDeals = () => {
  const [offer, setOffer] = useState();
  const classes = useStyles();
  const params = useParams();
  const { dealerList } = useContext(DealerContext);

  const {
    deals,
  } = DealsFilter()

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    setOffer(deals.filter((d) => d.id == params.stockId)[0]);
  }, [params, deals]);

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.CardHolder}>
          <div className={classes.title}>
            <Typography variant="h4" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>{offer?.headline}</span> </strong>
              <span className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></span>
            </Typography>
          </div>
          <div className={classes.cardContent}>

          </div>
        </div>
        <div className={classes.CardHolderWrap}>
          <div className={classes.CardHolder50}>
            <img alt="offer" src={offer?.imageUrl} width="100%" />
            <div className={classes.title}>
              <Typography variant="h4" className={classes.vehicleTitle}>
                {offer?.make} {offer?.model}
              </Typography>
            </div>
            <div className={classes.title} style={{ flexDirection: 'column' }}>
              <ReactMarkdown style={{ whiteSpace: 'pre-line', paddingBottom: 10 }}>
                {offer?.introParagraph}
              </ReactMarkdown>
            </div>
            {
              offer?.isCashDeal ? null :
                (
                  <div className={classes.cardContent}>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Purchase Price
                      </Typography>
                      <Typography variant="body" className={classes.accent}>
                        R {offer?.price}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Monthly Installment
                      </Typography>
                      <Typography variant="body" className={classes.accent}>
                        R {offer?.monthlyInstallment}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Term
                      </Typography>
                      <Typography variant="body" className={classes.accent}>
                        {offer?.term}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Linked Interest Rate
                      </Typography>
                      <Typography variant="body" className={classes.accent}>
                        {offer?.rate}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Deposit
                      </Typography>
                      <Typography variant="body" className={classes.accent}>
                        {offer?.deposit} %
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Balloon Payment
                      </Typography>
                      <Typography variant="body" className={classes.accent}>
                        {offer?.balloonPayment} %
                      </Typography>
                    </div>
                  </div>
                )
            }
          </div>
          <div className={`${classes.CardHolder50} ${classes.stickyDiv}`}>
            <div className={classes.titleContactUs}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>
            <div className={classes.cardContent50}>
              <ContactUs
                dealerId={MASTER_DEALERID}
                masterDealerId={MASTER_DEALERID}
                dealers={dealerList}
                base_uri={BASE_URI}
                dealerType="multi_dealer"
                leadTypeId={1}
                variantId={offer?.variantId}
                dealerName={"Suzuki"} />
            </div>
          </div>
        </div>
      </div>
      <Typography variant="body" className={classes.tc}>
        {offer?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedSpecialDeals;
