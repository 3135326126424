import React from "react";
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import HomeComponent from './HomeComponent/HomeComponent';
import ScrollUpButton from "react-scroll-up-button";
import TopBar from './TopBar/TopBar';
import Footer from '../components/Footer/Footer'
import ShowRoomPage from "./Showroom/ShowroomPage";
import SellVehicle from './SellVehicle/SellVehicle';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
import VehicleFinance from './Finance/VehicleFinance';
import ContactUs from './ContactUs/ContactUs';
import SpecialDeals from './SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from './DetailedSpecialDeals/DetailedSpecialDeals';
import AboutUs from "./AboutUs/AboutUs";
import Popi from './Legal/Popia';
import Cookies from './Legal/Cookies';
import Terms from './Legal/Terms';
import BookService from './BookService/BookService';
import Parts from './Parts/Parts';
import UsedVehicles from './UsedVehicles/UsedVehicles';
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import BackImg from '../components/shared/assets/Background_Colour_White.svg';
import DealersNew from './Dealers/DealersNew';
import Custom404 from "./shared/not-found";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: `url(${BackImg})`,
    backgroundColor: '#f2f2f2',
  },
}));

export default function DashboardContainer() {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      {/* <Toolbar/> */}
      <TopBar link1Text='HOME' link2Text='New' link3Text='Sell' link4Text='Finance' link5Text='Contact Us' link6Text='Compare' link7Text='Favourites' />
      <Switch>
        <Route exact path="/" render={(props) => <HomeComponent />} />
        <Route exact path="/new-cars" render={(props) => <ShowRoomPage />} />
        <Route exact path="/about" render={(props) => <AboutUs />} />
        <Route path="/new-cars/:model/:stockId" render={(props) => <DetailedVehicle />} />
        <Route path="/bargain-deals/:model/:stockId" render={(props) => <DetailedVehicle />} />
        <Route exact path="/sell-your-vehicle" render={(props) => <SellVehicle />} />
        <Route exact path="/special-deals" render={(props) => <SpecialDeals />} />
        <Route path="/special-deals/:model/:stockId" render={(props) => <DetailedSpecialDeals />} />
        <Route exact path='/apply-for-finance' component={VehicleFinance} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route path='/requestergws' component={Popi} />
        <Route path='/privacy' component={Popi} />
        <Route path='/cookies' component={Cookies} />
        <Route path='/terms' component={Terms} />
        <Route path='/book-a-service' component={BookService} />
        <Route path='/parts' component={Parts} />
        <Route exact path="/pre-owned" render={(props) => <UsedVehicles />} />
        <Route path="/pre-owned/:model/:stockId" render={(props) => <DetailedUsedVehicle />} />
        <Route exact path="/branch-locator" render={(props) => <DealersNew />} />
        <Route path='*' render={(props) => <Custom404 />} />
      </Switch>
      <Footer />
      <ScrollUpButton />
    </main>
  );
}